import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import moment from 'moment';
import { getMessagesGPT, postMessageGPT } from '../../../../../crud/chat.crud';
import { getResponseMessage } from '../../../../../utils/utils';
import ChatSocket from '../../../../../utils/socket';

export type TMessageGPT = {
  content: string;
  date: string;
  id: string;
  role: 'assistant' | 'user';
};

export const useGetMessagesGPT = () => {
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const [loadingGPT, setLoading] = useState(false);
  const [messagesGPT, setMessages] = useState<TMessageGPT[]>([]);

  const fetchMessagesGPT = useCallback(async (user_id?: number) => {
    await ChatSocket.getMyAuthToken();
    setLoading(true);
    getMessagesGPT(user_id)
      .then((res: any) => {
        setMessages(res.data.data.filter((i: TMessageGPT) => !!i && !!i?.content));
      })
      .catch(e => {
        enqueueSnackbar(`${intl.formatMessage({ id: 'ERROR' })}: ${getResponseMessage(e)}`, {
          variant: 'error',
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const sendMessage = (message: string) => {
    const data = new FormData();
    data.append('message', message);
    setMessages(state => [
      ...state,
      {
        content: message,
        id: moment().format('DD.MM.YYYY HH:mm:ss'),
        date: moment().format('DD.MM.YYYY HH:mm:ss'),
        role: 'user',
      },
    ]);
    postMessageGPT(data)
      .then((res: any) => {
        const newMessages: TMessageGPT[] = res.data.data.choices.map((i: any) => ({
          content: i.message.content,
          id: moment().format('DD.MM.YYYY HH:mm:ss'),
          date: moment().format('DD.MM.YYYY HH:mm:ss'),
          role: 'assistant',
        }));
        setMessages(state => [...state, ...newMessages]);
      })
      .catch(e => {
        enqueueSnackbar(`${intl.formatMessage({ id: 'ERROR' })}: ${getResponseMessage(e)}`, {
          variant: 'error',
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const clearMessages = useCallback(() => {
    setMessages([]);
  }, []);

  return { fetchMessagesGPT, loadingGPT, messagesGPT, sendMessage, clearMessages };
};
