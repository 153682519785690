import React, { useEffect } from 'react';
import { Tab, Tabs, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';

import { useStyles } from '../hooks/useStyles';
import { useFormatMessage } from '../../../../hooks';
import { UserRoles } from '../../../../interfaces/user';
import { useGetSettingGPT } from '../hooks/useGetSettingGPT';
import { ButtonWithLoader } from '../../../../components/ui/Buttons';
import Preloader from '../../../../components/other/Preloader/Preloader';
import { useEditSettingGPT } from '../hooks/useEditSettingGPT';
import { a11yProps } from '../../../../components/other/Tab/TabPanel';

const Promt = () => {
  const classes = useStyles();
  const fm = useFormatMessage();

  const {
    fetchSetting: fetchSettingRU,
    loading: loadingRU,
    setting: settingRU,
  } = useGetSettingGPT();

  const {
    fetchSettingEdit: fetchSettingEditRU,
    loadingEdit: loadingEditRU,
    successEdit,
  } = useEditSettingGPT();

  const {
    fetchSetting: fetchSettingEN,
    loading: loadingEN,
    setting: settingEN,
  } = useGetSettingGPT();

  const { fetchSettingEdit: fetchSettingEditEN, loadingEdit: loadingEditEN } =
    useEditSettingGPT();

  const {
    fetchSetting: fetchSettingCZ,
    loading: loadingCZ,
    setting: settingCZ,
  } = useGetSettingGPT();

  const { fetchSettingEdit: fetchSettingEditCZ, loadingEdit: loadingEditCZ } =
    useEditSettingGPT();

  const {
    fetchSetting: fetchSettingVendor,
    loading: loadingVendor,
    setting: settingVendor,
  } = useGetSettingGPT();

  const { fetchSettingEdit: fetchSettingEditVendor, loadingEdit: loadingEditVendor } =
    useEditSettingGPT();

  const {
    fetchSetting: fetchSettingManager,
    loading: loadingManager,
    setting: settingManager,
  } = useGetSettingGPT();

  const { fetchSettingEdit: fetchSettingEditManager, loadingEdit: loadingEditManager } =
    useEditSettingGPT();

  const {
    fetchSetting: fetchSettingBuyer,
    loading: loadingBuyer,
    setting: settingBuyer,
  } = useGetSettingGPT();

  const { fetchSettingEdit: fetchSettingEditBuyer, loadingEdit: loadingEditBuyer } =
    useEditSettingGPT();

  const {
    fetchSetting: fetchSettingVendorStaff,
    loading: loadingVendorStaff,
    setting: settingVendorStaff,
  } = useGetSettingGPT();

  const {
    fetchSettingEdit: fetchSettingEditVendorStaff,
    loadingEdit: loadingEditVendorStaff,
  } = useEditSettingGPT();

  const {
    fetchSetting: fetchSettingBuyerStaff,
    loading: loadingBuyerStaff,
    setting: settingBuyerStaff,
  } = useGetSettingGPT();

  const { fetchSettingEdit: fetchSettingEdiBuyerStaff, loadingEdit: loadingEditBuyerStaff } =
    useEditSettingGPT();

  const [tabValue, setTabValue] = React.useState(0);

  const { values, handleChange, handleSubmit } = useFormik({
    initialValues: {
      valueRU: settingRU?.value || '',
      valueEN: settingEN?.value || '',
      valueCZ: settingCZ?.value || '',
      valueVendor: settingVendor?.value || '',
      valueManager: settingManager?.value || '',
      valueBuyer: settingBuyer?.value || '',
      valueVendorStaff: settingVendorStaff?.value || '',
      valueBuyerStaff: settingBuyerStaff?.value || '',
    },
    enableReinitialize: true,
    onSubmit: values => {
      fetchSettingEditRU({
        type: 'promt',
        locale: 'ru',
        value: values.valueRU,
        disableSuccess: true,
      });
      fetchSettingEditEN({
        type: 'promt',
        locale: 'en',
        value: values.valueEN,
        disableSuccess: true,
      });
      fetchSettingEditCZ({
        type: 'promt',
        locale: 'cz',
        value: values.valueCZ,
        disableSuccess: true,
      });
      fetchSettingEditVendor({
        type: 'promt',
        user_role: UserRoles.ROLE_VENDOR,
        value: values.valueVendor,
        disableSuccess: true,
      });
      fetchSettingEditManager({
        type: 'promt',
        user_role: UserRoles.ROLE_MANAGER,
        value: values.valueManager,
        disableSuccess: true,
      });
      fetchSettingEditBuyer({
        type: 'promt',
        user_role: UserRoles.ROLE_BUYER,
        value: values.valueBuyer,
        disableSuccess: true,
      });
      fetchSettingEditVendorStaff({
        type: 'promt',
        user_role: UserRoles.ROLE_VENDOR_STAFF,
        value: values.valueVendorStaff,
        disableSuccess: true,
      });
      fetchSettingEdiBuyerStaff({
        type: 'promt',
        user_role: UserRoles.ROLE_BUYER_STAFF,
        value: values.valueBuyerStaff,
      });
    },
  });

  useEffect(() => {
    fetchSettingRU('promt', 'ru');
    fetchSettingEN('promt', 'en');
    fetchSettingCZ('promt', 'cz');
    fetchSettingVendor('promt', undefined, UserRoles.ROLE_VENDOR);
    fetchSettingManager('promt', undefined, UserRoles.ROLE_MANAGER);
    fetchSettingBuyer('promt', undefined, UserRoles.ROLE_BUYER);
    fetchSettingVendorStaff('promt', undefined, UserRoles.ROLE_VENDOR_STAFF);
    fetchSettingBuyerStaff('promt', undefined, UserRoles.ROLE_BUYER_STAFF);
  }, []);

  useEffect(() => {
    if (successEdit) {
      fetchSettingRU('promt', 'ru');
      fetchSettingEN('promt', 'en');
      fetchSettingCZ('promt', 'cz');
      fetchSettingVendor('promt', undefined, UserRoles.ROLE_VENDOR);
      fetchSettingManager('promt', undefined, UserRoles.ROLE_MANAGER);
      fetchSettingBuyer('promt', undefined, UserRoles.ROLE_BUYER);
      fetchSettingVendorStaff('promt', undefined, UserRoles.ROLE_VENDOR_STAFF);
      fetchSettingBuyerStaff('promt', undefined, UserRoles.ROLE_BUYER_STAFF);
    }
  }, [successEdit]);

  return (
    <>
      <Tabs
        value={tabValue}
        onChange={(_: React.ChangeEvent<{}>, newValue: number) => {
          setTabValue(newValue);
        }}
        variant='scrollable'
        indicatorColor='primary'
        textColor='primary'
        aria-label='tabs'
      >
        <Tab label={fm('PROMT.ROLES')} {...a11yProps(0)} />
        <Tab label={fm('PROMT.LANG')} {...a11yProps(1)} />
      </Tabs>
      <div className={classes.formEditCenter}>
        <div className={classes.cardEditFull}>
          <div style={{ padding: 16 }}>
            {loadingRU ||
            loadingEN ||
            loadingCZ ||
            loadingBuyer ||
            loadingVendor ||
            loadingManager ||
            loadingVendorStaff ||
            loadingBuyerStaff ? (
              <Preloader />
            ) : (
              <>
                <div style={{ display: tabValue === 0 ? 'contents' : 'none', width: '100%' }}>
                  <Typography style={{ marginBottom: 16 }} variant='h6'>
                    {fm('PROMT.LABEL')}
                  </Typography>
                  <Typography variant='h6'>{fm('USER.ROLES.VENDOR')}</Typography>
                  <TextField
                    type='text'
                    margin='normal'
                    name='valueVendor'
                    variant='outlined'
                    multiline
                    minRows={10}
                    value={values.valueVendor}
                    onChange={handleChange}
                  />

                  <Typography variant='h6'>{fm('USER.ROLES.MANAGER')}</Typography>
                  <TextField
                    type='text'
                    margin='normal'
                    name='valueManager'
                    variant='outlined'
                    multiline
                    minRows={10}
                    value={values.valueManager}
                    onChange={handleChange}
                  />

                  <Typography variant='h6'>{fm('USER.ROLES.BUYER')}</Typography>
                  <TextField
                    type='text'
                    margin='normal'
                    name='valueBuyer'
                    variant='outlined'
                    multiline
                    minRows={10}
                    value={values.valueBuyer}
                    onChange={handleChange}
                  />

                  <Typography variant='h6'> {fm('USER.ROLES.VENDOR.STAFF')}</Typography>
                  <TextField
                    type='text'
                    margin='normal'
                    name='valueVendorStaff'
                    variant='outlined'
                    multiline
                    minRows={10}
                    value={values.valueVendorStaff}
                    onChange={handleChange}
                  />

                  <Typography variant='h6'> {fm('USER.ROLES.BUYER.STAFF')}</Typography>
                  <TextField
                    type='text'
                    margin='normal'
                    name='valueBuyerStaff'
                    variant='outlined'
                    multiline
                    minRows={10}
                    value={values.valueBuyerStaff}
                    onChange={handleChange}
                  />
                </div>
                <div style={{ display: tabValue === 1 ? 'contents' : 'none', width: '100%' }}>
                  <Typography style={{ marginBottom: 16 }} variant='h6'>
                    {fm('PROMT.LANG.LABEL')}
                  </Typography>
                  <Typography variant='h6'> {fm(`CATEGORY.RU.LANGUAGE`)}</Typography>
                  <TextField
                    type='text'
                    margin='normal'
                    name='valueRU'
                    variant='outlined'
                    multiline
                    minRows={10}
                    value={values.valueRU}
                    onChange={handleChange}
                  />

                  <Typography variant='h6'> {fm(`CATEGORY.EN.LANGUAGE`)}</Typography>
                  <TextField
                    type='text'
                    margin='normal'
                    name='valueEN'
                    variant='outlined'
                    multiline
                    minRows={10}
                    value={values.valueEN}
                    onChange={handleChange}
                  />

                  <Typography variant='h6'> {fm(`CATEGORY.CZ.LANGUAGE`)}</Typography>
                  <TextField
                    type='text'
                    margin='normal'
                    name='valueCZ'
                    variant='outlined'
                    multiline
                    minRows={10}
                    value={values.valueCZ}
                    onChange={handleChange}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div className={classes.containerBtns}>
        <ButtonWithLoader
          onPress={handleSubmit}
          loading={
            loadingEditVendor ||
            loadingEditManager ||
            loadingEditBuyer ||
            loadingEditVendorStaff ||
            loadingEditBuyerStaff ||
            loadingEditRU ||
            loadingEditEN ||
            loadingEditCZ
          }
        >
          {fm('COMMON.BUTTON.SAVE')}
        </ButtonWithLoader>
      </div>
    </>
  );
};

export default React.memo(Promt);
