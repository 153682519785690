import React, { useEffect, useLayoutEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { actions as chatActions } from '../../../../store/ducks/chat.duck';
import Preloader from '../../../../components/other/Preloader/Preloader';
import { IAppState } from '../../../../store/rootDuck';
import { useStyle } from './styles';
import { TChannel } from '../../../../interfaces/chat';
import { useGetChatMessages } from './hooks/useGetChatMessages';
import { useInit } from './hooks/useInit';
import ListChats from './components/ListChats';
import ListMessages from './components/ListMessages';
import { useCreateChat } from './hooks/useCreateChat';
import { useDefineUserRole } from '../../../../hooks';
import { useGetChats } from './hooks/useGetChats';
import { setLayoutFooter, setLayoutSubheader } from '../../../../utils/layout';
import { getRoleByValueTab } from './utils';
import TabsRole from './components/TabsRole';
import { useGetMessagesGPT } from './hooks/useGetMessagesGPT';

const ChatsPage: React.FC = () => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state, pathname } = useLocation();
  const [value, setValue] = useState(0);
  const [userName, setUserName] = React.useState('');
  const [currentChat, setCurrentChat] = useState<TChannel | null>(null);
  const [getMessages, loadingMessages] = useGetChatMessages();
  const [init] = useInit();

  const profileMe = useSelector(({ profile }: IAppState) => profile.me, shallowEqual);
  const isVendor = useDefineUserRole(profileMe, ['ROLE_VENDOR', 'ROLE_VENDOR_STAFF']);
  const isBuyer = useDefineUserRole(profileMe, ['ROLE_BUYER', 'ROLE_BUYER_STAFF']);
  const isAdmin = useDefineUserRole(profileMe, 'ROLE_ADMIN');

  const { createChatFetch, createChat } = useCreateChat();
  const [getChats, loadingPage] = useGetChats();
  const { fetchMessagesGPT, loadingGPT, messagesGPT, sendMessage } = useGetMessagesGPT();

  const { chats, companies, page, perPage, total } = useSelector(({ chat }: IAppState) => {
    return {
      chats: chat.chats,
      companies: chat.companies,
      page: chat.page,
      perPage: chat.perPage,
      total: chat.total,
    };
  }, shallowEqual);

  setLayoutSubheader({ show: false });

  setLayoutFooter({ show: false });

  useLayoutEffect(() => {
    if (isVendor && chats?.length === 0 && !profileMe?.company?.id) {
      createChatFetch({ userId: profileMe?.id });
    }
    if (isVendor && chats?.length === 0 && !!profileMe?.company?.id) {
      createChatFetch({ companyId: profileMe.company.id });
    }
    if (isVendor && chats && chats?.length > 0) {
      if (!profileMe?.company?.id) {
        const newChat = chats.find(item => !item.company_id);
        !newChat && createChatFetch({ userId: profileMe?.id });
      }
      if (profileMe?.company?.id) {
        const newChat = chats.find(item => !!item.company_id);
        !newChat && createChatFetch({ companyId: profileMe.company.id });
      }
    }
  }, [isVendor, chats]);

  useLayoutEffect(() => {
    if (isBuyer && chats?.length === 0) {
      createChatFetch({ userId: profileMe?.id });
    }
  }, [isBuyer, chats]);

  useLayoutEffect(() => {
    if (value !== 4) {
      getChats(1, 30, undefined, getRoleByValueTab(value), userName.trim());
    } else {
      fetchMessagesGPT();
    }
  }, [value]);

  useLayoutEffect(() => {
    if ((state?.createCompanyChat || state?.createUserChat) && chats && chats.length > 0) {
      if (isVendor && profileMe?.company) {
        const newChat = chats.find(item => !!item.company_id);
        newChat && setCurrentChat(newChat);
      }
      if (isVendor && !profileMe?.company) {
        const newChat = chats.find(item => !item.company_id);
        newChat && setCurrentChat(newChat);
      }
      if (isBuyer) {
        const newChat = chats.find(item => item.user_id);
        newChat && setCurrentChat(newChat);
      }
      if (isAdmin) {
        state?.createCompanyChat?.id &&
          createChatFetch({ companyId: state?.createCompanyChat?.id });
        state?.createUserChat?.id && createChatFetch({ userId: state?.createUserChat?.id });
      }
      navigate(pathname, {});
    }
  }, [state, chats]);

  useEffect(() => {
    if (createChat) {
      setCurrentChat(createChat);
      dispatch(chatActions.updateChats({ page, perPage }));
    }
  }, [createChat]);

  useLayoutEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (currentChat) {
      if (typeof currentChat.id === 'string') {
        const mattermostId = currentChat.id;
        getMessages(mattermostId);
      } else {
        getMessages(currentChat.mattermost_id);
      }
    }
  }, [currentChat]);

  useEffect(() => {
    return () => {
      dispatch(chatActions.clearChat());
      dispatch(chatActions.clearChats());
    };
  }, []);

  if (!chats || !companies || !profileMe) return <Preloader />;

  return (
    <div className={classes.container}>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        {isAdmin && <TabsRole value={value} setValue={setValue} />}
        <ListChats
          chats={chats}
          setCurrentChat={setCurrentChat}
          loadingMessages={loadingMessages}
          page={page}
          perPage={perPage}
          total={total}
          getChats={getChats}
          loading={loadingPage}
          valueRole={value}
          userName={userName.trim()}
          setUserName={setUserName}
          isGPT={value === 4}
        />
      </div>
      <ListMessages
        currentChat={currentChat}
        loadingMessages={loadingMessages || loadingGPT}
        profileMe={profileMe}
        state={state}
        messagesGPT={messagesGPT}
        isGPT={value === 4}
        sendMessage={sendMessage}
      />
    </div>
  );
};

export default ChatsPage;
