import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getSettingGPT } from '../../../../crud/chat.crud';
import { UserRoles } from '../../../../interfaces/user';
import { IGPTSetting } from '../../../../interfaces/chat';

export const useGetSettingGPT = () => {
  const [loading, setLoading] = useState(false);
  const [setting, setSetting] = useState<IGPTSetting | null>(null);
  const dispatch = useDispatch();

  const fetchSetting = useCallback(
    async (
      type: 'main_promt' | 'promt' | 'dialog_opening' | 'avatar',
      locale?: string,
      user_role?: UserRoles
    ) => {
      setLoading(true);
      getSettingGPT(type, locale, user_role)
        .then(res => {
          setSetting(res.data.data);
        })
        .catch(e => {
          setSetting(null);
          //   enqueueSnackbar(`${fm('ERROR')}: ${getResponseMessage(e)}`);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [dispatch]
  );

  return {
    fetchSetting,
    setting,
    loading,
  };
};
