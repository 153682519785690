import React, { useEffect } from 'react';
import { Typography } from '@mui/material';

import useHomeStyles from '../../../../constants/homeStyles';
import { UploadImages } from '../../../../components/ui/UploadImages';
import { useGetSettingGPT } from '../hooks/useGetSettingGPT';
import { useEditSettingGPT } from '../hooks/useEditSettingGPT';
import Preloader from '../../../../components/other/Preloader/Preloader';
import { useFormatMessage } from '../../../../hooks';

const Avatar = () => {
  const homeClasses = useHomeStyles();
  const fm = useFormatMessage();
  const { fetchSetting, loading, setting } = useGetSettingGPT();
  const { fetchSettingEdit, successEdit } = useEditSettingGPT();

  useEffect(() => {
    fetchSetting('avatar');
  }, []);

  useEffect(() => {
    if (successEdit) {
      fetchSetting('avatar');
    }
  }, [successEdit]);

  const uploadAvatar = (files: File[]) => {
    fetchSettingEdit({ type: 'avatar', avatar: files[0] });
  };

  if (loading) return <Preloader />;

  return (
    <div className={homeClasses.classes.form}>
      <Typography variant='h6'>{fm('AVATAR.LABEL')}</Typography>
      <UploadImages
        images={
          setting?.value
            ? [
                {
                  path: setting.value,
                  id: 1,
                  name: 'avatar',
                  main: true,
                  extension: '',
                  mime_type: '',
                  small: '',
                  path_blob: '',
                  banner: true,
                },
              ]
            : []
        }
        uploadImages={uploadAvatar}
        withGallery
        withCrop
        cropAspect={1}
      />
    </div>
  );
};

export default React.memo(Avatar);
