/* eslint-disable no-nested-ternary */
import React, { useState, useLayoutEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import {
  Typography,
  Card,
  Table,
  CardContent,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  TableFooter,
  Dialog,
  DialogContent,
} from '@mui/material';
import { useIntl } from 'react-intl';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

import { SHeadTableCell } from '../../../../components/styledComponents/Tables';
import Preloader from '../../../../components/other/Preloader/Preloader';
import TablePaginator from '../../../../components/tableComponents/TablePaginator/TablePaginator';

import { setLayoutFooter, setLayoutSubheader } from '../../../../utils/layout';
import { IAppState } from '../../../../store/rootDuck';
import homeStyles from '../../../../constants/homeStyles';
import { useStyles } from './hooks/useStyles';
import { IUser } from '../../../../interfaces/user';
import { useFormatMessage } from '../../../../hooks';

import { useSearchUsers } from '../../users/users/hooks/useSearchUsers';
import { useGetChats } from './hooks/useGetChats';
import { useDeleteChat } from './hooks/useDeleteChat';
import AlertDialog from '../../../../components/ui/Dialog/AlertDialog';
import Filter from './components/Filter';
import { useGetMessagesGPT } from '../chatsPage/hooks/useGetMessagesGPT';
import ListMessages from '../chatsPage/components/ListMessages';
import { TRole } from '../../../auth/interfaces';

const ListChats: React.FC = () => {
  const intl = useIntl();
  const classes = useStyles();
  const homeClasses = homeStyles();
  const fm = useFormatMessage();
  const { fetch, loading, pagination, chats } = useGetChats();
  const { fetchMessagesGPT, loadingGPT, messagesGPT, clearMessages } = useGetMessagesGPT();
  const navigate = useNavigate();
  const { page, perPage, total } = pagination;
  const { me } = useSelector(({ profile: { me } }: IAppState) => ({ me }), shallowEqual);

  const { fetchDelete, successDelete } = useDeleteChat();
  const [userId, setUserId] = useState<{
    id: number;
    login: string;
    email: string;
    roles: TRole[];
  } | null>(null);
  const [deleteId, setDeleteId] = useState<number | null>(null);
  const [checkSearch, setCheckSearch] = useState('name');
  const [fio, setFio] = useState({} as IUser);
  const [user, setUser] = useState<IUser | null>(null);
  const [role, setRole] = useState('all');
  const [startDate, setStart] = useState('2023-01-01');
  const [endDate, setEnd] = useState(moment(new Date()).add(1, 'year').format('YYYY-MM-DD'));

  const {
    fetchUsers,
    loading: searchLoading,
    loadingPhone,
    page: searchPage,
    perPage: searchPerPage,
    users: searchUsers,
  } = useSearchUsers();

  useLayoutEffect(() => {
    userId && fetchMessagesGPT(userId.id);
  }, [userId]);

  useLayoutEffect(() => {
    me && !me?.is_admin && navigate('/error/error-v1');
  }, [me]);

  useLayoutEffect(() => {
    fetch({
      page: 1,
      per_page: 20,
      date_from: startDate,
      date_to: endDate,
      user_id: checkSearch === 'id' ? user?.id : undefined,
      email: checkSearch === 'name' ? fio?.email : undefined,
      phone: checkSearch === 'number' ? user?.phone : undefined,
      role: role === 'all' ? undefined : role,
    });
  }, []);

  useLayoutEffect(() => {
    successDelete &&
      fetch({
        page: 1,
        per_page: 20,
        date_from: startDate,
        date_to: endDate,
        user_id: checkSearch === 'id' ? user?.id : undefined,
        email: checkSearch === 'name' ? fio?.email : undefined,
        phone: checkSearch === 'number' ? user?.phone : undefined,
        role: role === 'all' ? undefined : role,
      });
  }, [successDelete]);

  setLayoutSubheader({
    title: intl.formatMessage({ id: 'CHATS.GPT' }),
    breadcrumb: [],
  });
  setLayoutFooter({ show: true });

  if (loading) return <Preloader />;

  return (
    <>
      <Row>
        <Col>
          <div className={classes.container}>
            <Filter
              startDate={startDate}
              setStart={setStart}
              endDate={endDate}
              setEnd={setEnd}
              fetch={fetch}
              checkSearch={checkSearch}
              setCheckSearch={setCheckSearch}
              user={user}
              setUser={setUser}
              fio={fio}
              setFio={setFio}
              role={role}
              fetchUsers={fetchUsers}
              searchLoading={searchLoading}
              searchPage={searchPage}
              searchPerPage={searchPerPage}
              searchUsers={searchUsers}
              pagination={pagination}
              loadingPhone={loadingPhone}
              setRole={setRole}
            />

            {loading ? (
              <div className={classes.preloaderContainer}>
                <Preloader />
              </div>
            ) : !chats.length ? (
              <Typography className={classes.empty} component='h5' variant='h5'>
                {fm('COMMON.LIST.EMPTY')}
              </Typography>
            ) : (
              <Card className={classes.tableContainer}>
                <CardContent className={homeClasses.classes.tableContainer}>
                  <Table aria-label='simple table'>
                    <TableHead>
                      <TableRow>
                        <SHeadTableCell>{fm('USER.ID')}</SHeadTableCell>
                        <SHeadTableCell>{fm('USER.EDIT.LOGIN')}</SHeadTableCell>
                        <SHeadTableCell>{fm('USER.EDIT.EMAIL')}</SHeadTableCell>
                        <SHeadTableCell>{fm('MESSAGES.NUMBER')}</SHeadTableCell>
                        <SHeadTableCell>{fm('DATE.UPDATE.CHAT')}</SHeadTableCell>
                        <SHeadTableCell align='right'>
                          {fm('USER.TABLE.ACTION')}
                        </SHeadTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {chats?.map((item, index) => (
                        <TableRow key={index}>
                          <TableCell component='th' scope='row'>
                            {item.user?.id || '-'}
                          </TableCell>
                          <TableCell component='th' scope='row'>
                            {item.user?.login || '-'}
                          </TableCell>
                          <TableCell component='th' scope='row'>
                            {item.user?.email || '-'}
                          </TableCell>
                          <TableCell component='th' scope='row'>
                            {item.messages_num || '-'}
                          </TableCell>
                          <TableCell component='th' scope='row'>
                            {item.updated_at
                              ? moment(item.updated_at.split('+')[0]).format(
                                  'YYYY-MM-DD hh:mm'
                                )
                              : '-'}
                          </TableCell>
                          <TableCell align='right'>
                            <div className={classes.actions}>
                              <IconButton
                                color='secondary'
                                aria-label='previous'
                                onClick={() => item.user?.id && setUserId(item.user)}
                              >
                                <VisibilityIcon color='primary' />
                              </IconButton>
                              <IconButton
                                color='secondary'
                                aria-label='previous'
                                onClick={() => item.user?.id && setDeleteId(item.user.id)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePaginator
                          page={page}
                          realPerPage={chats.length}
                          perPage={perPage}
                          total={total}
                          fetchRows={(data: any) =>
                            fetch({
                              ...data,
                              user_id: checkSearch === 'id' ? user?.id : undefined,
                              email: checkSearch === 'name' ? fio?.email : undefined,
                              phone: checkSearch === 'number' ? user?.phone : undefined,
                              role: role === 'all' ? undefined : role,
                              date_from: startDate,
                              date_to: endDate,
                            })
                          }
                          label={fm('CHATS.PER.PAGE')}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </CardContent>
              </Card>
            )}
          </div>
        </Col>
      </Row>
      <AlertDialog
        open={!!deleteId}
        message={fm('CHAT.TITLE.DELETE')}
        okText={fm('CATEGORIES.DELETE.OK')}
        cancelText={fm('CATEGORIES.DELETE.CANCEL')}
        handleClose={() => setDeleteId(null)}
        handleAgree={() => {
          if (deleteId) {
            fetchDelete(deleteId);
            setDeleteId(null);
          }
        }}
        styleActions={{ marginBottom: 12 }}
      />

      <Dialog
        open={!!userId}
        onClose={() => {
          setUserId(null);
          clearMessages();
        }}
        scroll='paper'
        aria-labelledby='scroll-dialog-title'
        aria-describedby='scroll-dialog-description'
        fullWidth
        maxWidth='md'
        slotProps={{
          backdrop: { classes: { root: classes.backdrop } },
        }}
      >
        <DialogContent>
          {me && (
            <ListMessages
              currentChat={null}
              loadingMessages={loadingGPT}
              profileMe={me}
              messagesGPT={messagesGPT}
              isGPT
              isFull
              showUser={userId?.email || userId?.login || 'Пользователь'}
              btnDelete={() => userId && setDeleteId(userId.id)}
            />
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ListChats;
