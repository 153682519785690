import React, { useCallback } from 'react';
import { Card, FormControlLabel, RadioGroup, Radio, TextField, MenuItem } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { useIntl } from 'react-intl';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import moment from 'moment';

import { useStyles } from '../hooks/useStyles';
import { IUser, UserRoles } from '../../../../../interfaces/user';
import { useFormatMessage } from '../../../../../hooks';

import AutocompleteStaff from '../../../../../components/AutocompleteStaff';
import AutocompleteStaffPhone from '../../../../../components/AutocompleteStaffPhone';
import { locales } from '../../../shifts/shiftPage/components/FormShiftPage';
import { TUserParamsSearch } from '../../../../../components/ui/UsersFilter/types';

type TProps = {
  startDate: string;
  setStart: (value: string) => void;
  endDate: string;
  setEnd: (value: string) => void;
  fetch: (params: {
    user_id?: number;
    role?: string;
    phone?: string;
    email?: string;
    date_from?: string;
    date_to?: string;
    page?: number;
    per_page?: number;
  }) => void;
  checkSearch: string;
  user: IUser | null;
  setUser: (value: IUser | null) => void;
  fio: IUser;
  setFio: (value: IUser) => void;
  role: string;
  fetchUsers: (params: Partial<TUserParamsSearch>) => void;
  searchPage: number;
  searchPerPage: number;
  setCheckSearch: (value: string) => void;
  searchUsers: IUser[];
  searchLoading: boolean;
  pagination: {
    page: number;
    perPage: number;
    total: number;
  };
  loadingPhone: boolean;
  setRole: (value: string) => void;
};

const Filter: React.FC<TProps> = ({
  checkSearch,
  startDate,
  setStart,
  endDate,
  setEnd,
  fetch,
  user,
  fio,
  role,
  fetchUsers,
  setCheckSearch,
  searchPage,
  searchPerPage,
  setFio,
  setUser,
  searchUsers,
  searchLoading,
  pagination,
  loadingPhone,
  setRole,
}) => {
  const intl = useIntl();
  const classes = useStyles();
  const fm = useFormatMessage();
  const { page, perPage } = pagination;

  const handleStartDateChange = (date: Date) => {
    setStart(moment(date).format('YYYY-MM-DD'));
    if (endDate && moment(endDate).isBefore(date, 'day')) {
      setEnd(moment(date).format('YYYY-MM-DD'));
      fetch({
        page: 1,
        per_page: 20,
        date_from: moment(date).format('YYYY-MM-DD'),
        date_to: moment(date).format('YYYY-MM-DD'),
        user_id: checkSearch === 'id' ? user?.id : undefined,
        email: checkSearch === 'name' ? fio?.email : undefined,
        phone: checkSearch === 'number' ? user?.phone : undefined,
        role: role === 'all' ? undefined : role,
      });
    } else {
      fetch({
        page: 1,
        per_page: 20,
        date_from: moment(date).format('YYYY-MM-DD'),
        date_to: endDate,
        user_id: checkSearch === 'id' ? user?.id : undefined,
        email: checkSearch === 'name' ? fio?.email : undefined,
        phone: checkSearch === 'number' ? user?.phone : undefined,
        role: role === 'all' ? undefined : role,
      });
    }
  };

  const searchGetUsers = useCallback(
    (data: { fio?: string; phone?: string; id?: number }) => {
      fetchUsers({
        page: searchPage,
        per_page: searchPerPage,
        email: data.fio,
        phone: data.phone,
        id: data.id,
      });
    },
    [fio, searchPage, searchPerPage]
  );

  return (
    <div className={classes.filterColTop}>
      <Card className={classes.card}>
        <RadioGroup
          name='checkSearch'
          value={checkSearch}
          onChange={e => {
            e.currentTarget.value && setCheckSearch(e.currentTarget.value);
            setFio({} as IUser);
            setUser(null);
            fetch({
              page: 1,
              per_page: 20,
              date_from: startDate,
              date_to: endDate,
              user_id: checkSearch === 'id' ? user?.id : undefined,
              email: checkSearch === 'name' ? fio?.email : undefined,
              phone: checkSearch === 'number' ? user?.phone : undefined,
              role: role === 'all' ? undefined : role,
            });
          }}
          style={{ flexDirection: 'row' }}
        >
          <FormControlLabel
            value='id'
            control={<Radio color='primary' />}
            label={fm('USER.SEARCH.STAFF.ID')}
          />
          <FormControlLabel
            value='name'
            control={<Radio color='primary' />}
            label={fm('AUTH.MAIN.TAB.BY_EMAIL')}
          />
          <FormControlLabel
            value='number'
            control={<Radio color='primary' />}
            label={fm('USER.SEARCH.PHONE')}
          />
        </RadioGroup>
        {checkSearch === 'name' && (
          <AutocompleteStaff
            options={searchUsers || []}
            loading={searchLoading}
            inputValue={fio}
            isEmail
            editable
            label={fm('USER.SEARCH.BY.EMAIL')}
            inputClassName={{ width: '100%' }}
            searchStaff={searchGetUsers}
            clearUsers={() => {
              fetch({
                page,
                per_page: perPage,
                date_from: startDate,
                date_to: endDate,
                role: role === 'all' ? undefined : role,
              });
              setUser(null);
            }}
            setSelectedValue={value => {
              setFio(value);
              setUser(null);
              fetch({
                page: 1,
                per_page: 20,
                email: value.email,
                date_from: startDate,
                date_to: endDate,
                role: role === 'all' ? undefined : role,
              });
            }}
            disable={false}
          />
        )}
        {checkSearch === 'number' && (
          <AutocompleteStaffPhone
            options={searchUsers || []}
            loading={loadingPhone}
            inputValue={user}
            label={fm('USER.SEARCH.STAFF.PHONE')}
            inputClassName={{ width: '100%' }}
            searchStaff={searchGetUsers}
            clearUsers={() => {
              fetch({
                page,
                per_page: perPage,
                date_from: startDate,
                date_to: endDate,
                role: role === 'all' ? undefined : role,
              });
            }}
            setSelectedValue={value => {
              setFio({} as IUser);
              setUser(value);
              fetch({
                page: 1,
                per_page: 20,
                phone: value.phone,
                date_from: startDate,
                date_to: endDate,
                role: role === 'all' ? undefined : role,
              });
            }}
          />
        )}
        {checkSearch === 'id' && (
          <AutocompleteStaffPhone
            options={searchUsers || []}
            loading={loadingPhone}
            isId
            inputValue={user}
            label={fm('USER.SEARCH.STAFF.ID')}
            inputClassName={{ width: '100%' }}
            searchStaff={searchGetUsers}
            clearUsers={() => {
              fetch({
                page,
                per_page: perPage,
                date_from: startDate,
                date_to: endDate,
                role: role === 'all' ? undefined : role,
              });
            }}
            setSelectedValue={value => {
              setFio({} as IUser);
              setUser(value);
              fetch({
                page: 1,
                per_page: 20,
                user_id: value.id,
                date_from: startDate,
                date_to: endDate,
                role: role === 'all' ? undefined : role,
              });
            }}
          />
        )}
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locales[intl.locale]}>
          <div className={classes.containerPicker}>
            <DatePicker
              label={fm('TENDER.DATE.START')}
              value={startDate ? dayjs(startDate) : null}
              onChange={date => {
                handleStartDateChange(dayjs(date).toDate());
              }}
              sx={{ width: '100%' }}
            />
          </div>
          <div className={classes.containerPicker}>
            <DatePicker
              label={fm('TENDER.DATE.END')}
              value={endDate ? dayjs(endDate) : null}
              onChange={date => {
                fetch({
                  page: 1,
                  per_page: 20,
                  date_from: startDate,
                  date_to: moment(dayjs(date).toDate()).format('YYYY-MM-DD'),
                  user_id: checkSearch === 'id' ? user?.id : undefined,
                  email: checkSearch === 'name' ? fio?.email : undefined,
                  phone: checkSearch === 'number' ? user?.phone : undefined,
                  role: role === 'all' ? undefined : role,
                });
                setEnd(moment(dayjs(date).toDate()).format('YYYY-MM-DD'));
              }}
              sx={{ width: '100%' }}
            />
          </div>
        </LocalizationProvider>
        <TextField
          select
          margin='normal'
          label={fm('USER.EDIT.ROLE')}
          value={role}
          onChange={e => {
            setRole(e.target.value);
            fetch({
              page: 1,
              per_page: 20,
              date_from: startDate,
              date_to: endDate,
              user_id: checkSearch === 'id' ? user?.id : undefined,
              email: checkSearch === 'name' ? fio?.email : undefined,
              phone: checkSearch === 'number' ? user?.phone : undefined,
              role: e.target.value === 'all' ? undefined : e.target.value,
            });
          }}
        >
          <MenuItem value='all'>{fm('ACTIVITY.ALL')}</MenuItem>
          <MenuItem value={UserRoles.ROLE_VENDOR}>{fm('USER.ROLES.VENDOR')}</MenuItem>
          <MenuItem value={UserRoles.ROLE_ADMIN}>{fm('USER.ROLES.ADMIN')}</MenuItem>
          <MenuItem value={UserRoles.ROLE_MANAGER}>{fm('USER.ROLES.MANAGER')}</MenuItem>
          <MenuItem value={UserRoles.ROLE_BUYER}>{fm('USER.ROLES.BUYER')}</MenuItem>
          <MenuItem value={UserRoles.ROLE_VENDOR_STAFF}>
            {fm('USER.ROLES.VENDOR.STAFF')}
          </MenuItem>
          <MenuItem value={UserRoles.ROLE_BUYER_STAFF}>
            {fm('USER.ROLES.BUYER.STAFF')}
          </MenuItem>
          <MenuItem value={UserRoles.ROLE_CONTENT_MANAGER}>
            {fm('USER.ROLES.CONTENT.MANAGER')}
          </MenuItem>
        </TextField>
      </Card>
    </div>
  );
};

export default Filter;
