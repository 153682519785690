import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ProfileEditPage from './profile/ProfileEditPage';
import SettingGPTPage from '../gpt/SettingGPTPage';

export default function SettingsProfilePage() {
  return (
    <Routes>
      <Route path='profile' element={<ProfileEditPage />} />
      <Route path='gpt' element={<SettingGPTPage />} />
    </Routes>
  );
}
