import React, { useState } from 'react';
import { Tab, Tabs, Card } from '@mui/material';
import { setLayoutSubheader } from '../../../utils/layout';
import useHomeStyles from '../../../constants/homeStyles';
import { a11yProps } from '../../../components/other/Tab/TabPanel';
import MainPromt from './components/MainPromt';
import Promt from './components/Promt';
import DialogOpening from './components/DialogOpening';
import Avatar from './components/Avatar';
import { useFormatMessage } from '../../../hooks';

const SettingGPTPage = () => {
  const homeClasses = useHomeStyles();
  const fm = useFormatMessage();

  const [tabValue, setTabValue] = useState(0);

  setLayoutSubheader({
    title: 'GPT',
  });

  return (
    <Card className={homeClasses.classes.container}>
      <Tabs
        value={tabValue}
        onChange={(_: React.ChangeEvent<{}>, newValue: number) => {
          setTabValue(newValue);
        }}
        variant='scrollable'
        indicatorColor='primary'
        textColor='primary'
        aria-label='tabs'
      >
        {/* <Tab label='api_key' {...a11yProps(0)} /> */}
        <Tab label={fm('MAIN.PROMT')} {...a11yProps(0)} />
        <Tab label={fm('PROMT')} {...a11yProps(1)} />
        <Tab label={fm('DIALOG.OPENING')} {...a11yProps(2)} />
        <Tab label='avatar' {...a11yProps(3)} />
      </Tabs>
      {/* <div style={{ display: tabValue === 0 ? 'contents' : 'none', width: '100%' }}>
        <Apikey />
      </div> */}
      <div style={{ display: tabValue === 0 ? 'contents' : 'none', width: '100%' }}>
        <MainPromt />
      </div>
      <div style={{ display: tabValue === 1 ? 'contents' : 'none', width: '100%' }}>
        <Promt />
      </div>
      <div style={{ display: tabValue === 2 ? 'contents' : 'none', width: '100%' }}>
        <DialogOpening />
      </div>
      <div style={{ display: tabValue === 3 ? 'contents' : 'none', width: '100%' }}>
        <Avatar />
      </div>
    </Card>
  );
};

export default React.memo(SettingGPTPage);
