import { createStyles, makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: any) =>
  createStyles({
    containerPaper: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: 20,
      boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .3)',
    },
    content: {
      overflow: 'auto',
      height: '100%',
      width: '100%',
      backgroundColor: '#fafafb',
      display: 'flex',
      justifyContent: 'center',
    },
    container: {
      paddingTop: 50,
      paddingBottom: 50,
      [theme.breakpoints.down('md')]: {
        paddingLeft: 40,
        paddingRight: 40,
      },
      [theme.breakpoints.up('md')]: {
        paddingLeft: 50,
        paddingRight: 50,
      },
    },
    containerTop: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: 35,
      [theme.breakpoints.up('sm')]: {
        width: 400,
      },
    },
    containerBot: {
      // marginTop: 35,
    },
    imgContainer: {
      height: 400,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
        maxWidth: 280,
        height: 200,
      },
      [theme.breakpoints.only('sm')]: {
        maxWidth: 350,
        height: 250,
      },
    },
    img: {
      maxWidth: 430,
      [theme.breakpoints.down('xs')]: {
        maxWidth: 200,
      },
      [theme.breakpoints.only('sm')]: {
        maxWidth: 250,
      },
      cursor: 'pointer',
    },
    galleryContainer: {
      [theme.breakpoints.down('xs')]: {
        maxWidth: 280,
        maxHeigth: 280,
      },
      [theme.breakpoints.only('sm')]: {
        maxWidth: 350,
        maxHeigth: 350,
      },
    },
    productInfo: {
      paddingLeft: 35,
      [theme.breakpoints.down('xs')]: {
        paddingLeft: 0,
        marginTop: 25,
      },
    },
    addToCartBtn: {},
    actions: {
      marginTop: theme.spacing(1),
      [theme.breakpoints.down('xs')]: {
        marginTop: 10,
      },
    },
    richEditor: {
      [theme.breakpoints.up('lg')]: {
        width: 950,
      },
    },
    richEditor1: {},
    header: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 40,
    },
    listItem: {
      marginBottom: 15,
    },
    listValue: {
      fontSize: 19,
      fontWeight: 600,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      color: '#3f4254',
      marginBottom: 5,
    },
    listKey: {
      marginBottom: 0,
    },
    companyCard: {
      padding: '25px 30px',
      borderRadius: '4px',
      backgroundColor: '#f5f8fa',
    },
    card: {
      padding: '21px',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      width: '100%',
      [theme.breakpoints.down('md')]: {
        justifyContent: 'flex-start',
      },
    },
    cardEdit: {
      backgroundColor: '#ffff',
      width: 'calc((100% - 24px) / 2)',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    formEdit: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      padding: theme.spacing(3),
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    containerBtns: {
      display: 'flex',
      justifyContent: 'flex-start',
      padding: '16px 40px',
      width: '100%',
    },
    cardEditFull: {
      backgroundColor: '#ffff',
      width: '100%',
    },
    formEditCenter: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      padding: theme.spacing(3),
      justifyContent: 'center',
    },
  })
);
