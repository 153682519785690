import React, { useCallback } from 'react';
import { Card, TablePagination } from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { shallowEqual, useSelector } from 'react-redux';

import { useStyle } from '../styles';
import { TChannel } from '../../../../../interfaces/chat';
import { useGetChatsWithInfo } from '../hooks/useGetChatsWithInfo';
import { IAppState } from '../../../../../store/rootDuck';
import { useDefineUserRole, useFormatMessage } from '../../../../../hooks';
import TablePaginationActions from '../../../../../components/tableComponents/TablePaginator/TablePaginationActions';
import Preloader from '../../../../../components/other/Preloader/Preloader';
import { getRoleByValueTab } from '../utils';
import SearchField from './SearchField';

interface IProps {
  chats: TChannel[] | null;
  setCurrentChat: React.Dispatch<React.SetStateAction<TChannel | null>>;
  loadingMessages: boolean;
  page: number;
  perPage: number;
  total: number;
  getChats: (
    page: number,
    perPage: number,
    archived?: boolean,
    user_role?: string,
    user_name?: string
  ) => void;
  loading: boolean;
  realPerPage?: number;
  valueRole: number;
  userName: string;
  setUserName: React.Dispatch<React.SetStateAction<string>>;
  isGPT: boolean;
}

const ListChats: React.FC<IProps> = ({
  chats,
  setCurrentChat,
  loadingMessages,
  realPerPage,
  page,
  perPage,
  total,
  getChats,
  loading,
  valueRole,
  userName,
  setUserName,
  isGPT,
}) => {
  const classes = useStyle();
  const fm = useFormatMessage();
  const profileMe = useSelector(({ profile }: IAppState) => profile.me, shallowEqual);
  const isAdmin = useDefineUserRole(profileMe, 'ROLE_ADMIN');
  const isManager = useDefineUserRole(profileMe, 'ROLE_MANAGER');
  const isBuyer = useDefineUserRole(profileMe, ['ROLE_BUYER', 'ROLE_BUYER_STAFF']);
  const isVendor = useDefineUserRole(profileMe, ['ROLE_VENDOR', 'ROLE_VENDOR_STAFF']);

  const getNameChat = useCallback(
    (item: TChannel) => {
      if (isBuyer) return fm('SUPPORT');
      if (isVendor) {
        if (!item.company_id) return fm('SUPPORT');
        if (item.company_id) return fm('COMPANY.SUPPORT');
      }
      if (item?.user?.roles?.includes('ROLE_BUYER')) {
        return `${fm('USER.ROLES.BUYER')} ${item?.user?.phone || item?.user?.email || ''}`;
      }
      if (item?.user?.roles?.includes('ROLE_VENDOR')) {
        return `${fm('USER.ROLES.VENDOR')} ${item?.user?.email}`;
      }
      if (item?.user?.roles?.includes('ROLE_VENDOR')) {
        return `${fm('USER.ROLES.VENDOR')} ${item?.user?.email}`;
      }
      if (isAdmin || isManager) {
        return item.display_name;
      }
      // if (!item.company) {
      //   if ((isAdmin || isManager) && item.users_data) {
      //     const findPhone = item.users_data.find(user => user.phone);
      //     if (findPhone?.phone) return formatPhone(findPhone.phone);
      //   }
      //   return item.display_name;
      // }
      // if (isAdmin || isManager) {
      //   if (item.users_data) {
      //     const findPhone = item.users_data.find(user => user.phone);
      //     if (findPhone?.phone) return formatPhone(findPhone.phone);
      //   }
      //   return item.display_name;
      // }
      return fm('SUPPORT');
    },
    [isAdmin, isManager, isBuyer, isVendor]
  );

  const getUserName = useCallback(
    (currentChat: TChannel) => {
      if (currentChat && (isAdmin || isManager) && currentChat.users_data) {
        const user = currentChat.users_data.find(
          user => user.roles && user.roles.includes('ROLE_BUYER')
        );
        if (user)
          return `${user.last_name || ''} ${user.first_name || ''} ${user.middle_name || ''}`;
      }
    },
    [isAdmin, isManager]
  );

  const [channelsWithInfo] = useGetChatsWithInfo(chats);

  return (
    <Card className={classes.chats}>
      {isGPT ? (
        <></>
      ) : (
        <>
          <SearchField
            getChats={getChats}
            valueRole={valueRole}
            userName={userName}
            setUserName={setUserName}
          />
          <PerfectScrollbar className={classes.scrollBarChats}>
            {loading ? (
              <Preloader />
            ) : (
              <>
                {/* {!myCompanyChat && !isManager && !isAdmin && profileMe?.company?.id && (
              <div
                className={classes.chatsBox}
                onClick={() =>
                  !loadingMessages && newSupportChat(profileMe.company?.id.toString())
                }
              >
                <div className={classes.chatsInfo}>
                  <div className={classes.chatsAvatar}>
                    <b style={{ color: '#009ef7' }}>П</b>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <p className={classes.chatsName}>Поддержка</p>
                  </div>
                </div>
                <div className={classes.chatsTimeWrapper}>
                  <b className={classes.chatsTime} />
                </div>
              </div>
            )} */}
                {channelsWithInfo.map((item: TChannel) => {
                  const nameChat = getNameChat(item);
                  const userName = getUserName(item);
                  return (
                    <div
                      key={item.id.toString()}
                      className={classes.chatsBox}
                      onClick={() => !loadingMessages && setCurrentChat(item)}
                    >
                      <div className={classes.chatsInfo}>
                        <div className={classes.chatsAvatar}>
                          <b style={{ color: '#009ef7' }}>{nameChat[0]}</b>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          <p className={classes.chatsName}>{nameChat}</p>
                          {userName && <p className={classes.chatsName}>{userName}</p>}
                        </div>
                      </div>
                      <div className={classes.chatsTimeWrapper}>
                        <b className={classes.chatsTime}>{item.timeSinceLastPost || ''}</b>
                      </div>
                    </div>
                  );
                })}
              </>
            )}
          </PerfectScrollbar>
          <div style={{ display: 'flex', width: '100%' }}>
            <TablePagination
              onChange={() => {}}
              rowsPerPageOptions={[30]}
              count={total}
              rowsPerPage={perPage}
              page={page - 1}
              SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true,
              }}
              style={{ width: '100%' }}
              onPageChange={(event, newPage) =>
                getChats(newPage + 1, perPage, undefined, getRoleByValueTab(valueRole))
              }
              onRowsPerPageChange={event =>
                getChats(
                  1,
                  parseInt(event.target.value, 10),
                  undefined,
                  getRoleByValueTab(valueRole)
                )
              }
              ActionsComponent={TablePaginationActions}
              labelDisplayedRows={({ from, to, count }) =>
                `${from}${realPerPage === 1 ? '' : '-'}${realPerPage === 1 ? '' : to} ${fm(
                  'TABLE.FROM.LABEL'
                )} ${count}`
              }
            />
          </div>
        </>
      )}
    </Card>
  );
};

export default React.memo(ListChats);
