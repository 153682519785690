import React, { useEffect } from 'react';
import { TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';

import useHomeStyles from '../../../../constants/homeStyles';
import { ButtonWithLoader } from '../../../../components/ui/Buttons';
import { useFormatMessage } from '../../../../hooks';
import { useGetSettingGPT } from '../hooks/useGetSettingGPT';
import Preloader from '../../../../components/other/Preloader/Preloader';
import { useEditSettingGPT } from '../hooks/useEditSettingGPT';

const MainPromt = () => {
  const homeClasses = useHomeStyles();
  const fm = useFormatMessage();
  const { fetchSetting, loading, setting } = useGetSettingGPT();
  const { fetchSettingEdit, loadingEdit } = useEditSettingGPT();

  const { values, handleChange, handleSubmit } = useFormik({
    initialValues: {
      value: setting?.value || '',
    },
    enableReinitialize: true,
    onSubmit: values => {
      fetchSettingEdit({
        type: 'main_promt',
        value: values.value,
      });
    },
  });

  useEffect(() => {
    fetchSetting('main_promt');
  }, []);

  if (loading) return <Preloader />;

  return (
    <div className={homeClasses.classes.formFull}>
      <Typography variant='h6'>{fm('MAIN.PROMT.LABEL')}</Typography>
      <TextField
        type='text'
        margin='normal'
        name='value'
        onChange={handleChange}
        variant='outlined'
        multiline
        minRows={10}
        value={values.value}
      />
      <ButtonWithLoader loading={loadingEdit} onPress={handleSubmit}>
        {fm('COMMON.BUTTON.SAVE')}
      </ButtonWithLoader>
    </div>
  );
};

export default React.memo(MainPromt);
