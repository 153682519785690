import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getResponseMessage } from '../../../../utils/utils';
import { useFormatMessage } from '../../../../hooks';
import { editSettingGPT } from '../../../../crud/chat.crud';
import { UserRoles } from '../../../../interfaces/user';

export const useEditSettingGPT = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [loadingEdit, setLoading] = useState(false);
  const [successEdit, setSuccessEdit] = useState(false);
  const dispatch = useDispatch();
  const fm = useFormatMessage();

  const fetchSettingEdit = useCallback(
    async (values: {
      type: 'main_promt' | 'promt' | 'dialog_opening' | 'avatar';
      locale?: string;
      user_role?: UserRoles;
      value?: string;
      avatar?: File;
      disableSuccess?: boolean;
    }) => {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { type, locale, user_role, value, avatar, disableSuccess } = values;
      const data = new FormData();
      data.append('type', type);
      locale && data.append('locale', locale);
      user_role && data.append('user_role', user_role);
      value && data.append('value', value);
      avatar && data.append('avatar', avatar);
      setLoading(true);
      editSettingGPT(data)
        .then(() => {
          setSuccessEdit(true);
          !disableSuccess &&
            enqueueSnackbar(fm('PROMOCODE.SETTINGS.SUCCESS'), { variant: 'success' });
        })
        .catch(e => {
          enqueueSnackbar(`${fm('ERROR')}: ${getResponseMessage(e)}`);
        })
        .finally(() => {
          setTimeout(() => {
            setSuccessEdit(false);
          }, 200);
          setLoading(false);
        });
    },
    [dispatch]
  );

  return {
    fetchSettingEdit,
    loadingEdit,
    successEdit,
  };
};
