import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { getChatsGPT } from '../../../../../crud/chat.crud';
import { TGPTListItem } from '../../../../../interfaces/chat';
import { getResponseMessage } from '../../../../../utils/utils';

export const useGetChats = () => {
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [chats, setChats] = useState<TGPTListItem[]>([]);
  const [pagination, setPagination] = useState({
    page: 1,
    perPage: 20,
    total: 0,
  });

  const fetch = useCallback(
    async (params: {
      user_id?: number;
      role?: string;
      phone?: string;
      email?: string;
      date_from?: string;
      date_to?: string;
      page?: number;
      per_page?: number;
    }) => {
      setLoading(true);
      setChats([]);
      getChatsGPT(params)
        .then(async (res: any) => {
          setChats(res.data.data);
          setPagination({
            page: res.data.page,
            perPage: res.data.per_page,
            total: res.data.total,
          });
        })
        .catch(e => {
          enqueueSnackbar(`${intl.formatMessage({ id: 'ERROR' })}: ${getResponseMessage(e)}`, {
            variant: 'error',
          });
        })
        .finally(() => {
          setLoading(false);
        });
    },
    []
  );
  return { fetch, loading, chats, pagination };
};
