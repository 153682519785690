import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Button, Card } from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Preloader from '../../../../../components/other/Preloader/Preloader';
import { useStyle } from '../styles';
import { API_DOMAIN } from '../../../../../constants';
import { formatDate, isImage } from '../../../../../utils/utils';
import { TChannel } from '../../../../../interfaces/chat';
import { IAppState } from '../../../../../store/rootDuck';
import { IUser } from '../../../../../interfaces/user';
import InputMessage from './InputMessage';
import { useDefineUserRole, useFormatMessage } from '../../../../../hooks';
import ImageChat from './ImageChat';
import FileChat from './FileChat';
import { TMessageGPT } from '../hooks/useGetMessagesGPT';
import { useGetSettingGPT } from '../../../gpt/hooks/useGetSettingGPT';

interface IProps {
  currentChat: TChannel | null;
  loadingMessages: boolean;
  profileMe: IUser;
  state?: any;
  messagesGPT: TMessageGPT[];
  isGPT: boolean;
  sendMessage?: (message: string) => void;
  isFull?: boolean;
  showUser?: string;
  btnDelete?: () => void;
}

const ListMessages: React.FC<IProps> = ({
  state,
  currentChat,
  loadingMessages,
  profileMe,
  messagesGPT,
  isGPT,
  sendMessage,
  isFull,
  showUser,
  btnDelete,
}) => {
  const classes = useStyle();
  const fm = useFormatMessage();
  const scrollBarref = useRef<HTMLDivElement>(null);
  const isAdmin = useDefineUserRole(profileMe, 'ROLE_ADMIN');
  const isManager = useDefineUserRole(profileMe, 'ROLE_MANAGER');
  const isBuyer = useDefineUserRole(profileMe, ['ROLE_BUYER', 'ROLE_BUYER_STAFF']);
  const isVendor = useDefineUserRole(profileMe, ['ROLE_VENDOR', 'ROLE_VENDOR_STAFF']);
  const { chat, me } = useSelector(
    ({ chat }: IAppState) => ({
      chat: chat.chat,
      me: chat.me,
    }),
    shallowEqual
  );

  const { fetchSetting, loading: loadingDialog, setting } = useGetSettingGPT();

  const nameChatPhone = useMemo(() => {
    if (currentChat && (isAdmin || isManager)) {
      const { company, user } = currentChat;
      if (company) return `Продавец ${company.name || ''}`;
      if (user && user?.roles?.includes('ROLE_BUYER') && user.phone_numbers !== null)
        return `Покупатель ${user.phone}`;
      if (user && user?.roles?.includes('ROLE_VENDOR') && user.phone_numbers !== null)
        return `Продавец ${user.phone_numbers || ''}`;
    }
  }, [currentChat, isAdmin, isManager]);

  const getNameChat = useCallback(
    (item: TChannel) => {
      if (isBuyer) return fm('SUPPORT');
      if (isVendor) {
        if (!item.company_id) return fm('SUPPORT');
        if (item.company_id) return fm('COMPANY.SUPPORT');
      }
      if (item?.user?.roles?.includes('ROLE_BUYER')) {
        return `${fm('USER.ROLES.BUYER')} ${item?.user?.phone || item?.user?.email || ''}`;
      }
      if (item?.user?.roles?.includes('ROLE_VENDOR')) {
        return `${fm('USER.ROLES.VENDOR')} ${item?.user?.email}`;
      }
      if (item?.user?.roles?.includes('ROLE_VENDOR') && state === undefined) {
        return `${fm('USER.ROLES.VENDOR')} ${item?.user?.email}`;
      }
      if (isAdmin || isManager) {
        return item.display_name;
      }
      return fm('SUPPORT');
    },
    [isAdmin, isManager, nameChatPhone, isBuyer, isVendor]
  );

  const scrollToBottom = useCallback(() => {
    scrollBarref.current && scrollBarref.current.scrollIntoView();
  }, [chat, scrollBarref]);

  useEffect(() => {
    scrollToBottom();
  }, [chat, loadingMessages, scrollBarref]);

  useEffect(() => {
    if (isGPT) {
      scrollToBottom();
    }
  }, [isGPT, messagesGPT.length]);

  useEffect(() => {
    fetchSetting('dialog_opening', me?.locale || 'ru');
  }, []);

  return (
    <Card
      className={classes.chatCard}
      style={{
        width: isFull ? '100%' : undefined,
      }}
    >
      <>
        {(isGPT || currentChat) && (
          <div className={classes.chatHeader}>
            <b className={classes.chatName}>{`${
              isGPT ? 'ChatGPT' : getNameChat(currentChat!)
            }`}</b>
            <div className={classes.chatStatusWrapper}>
              <span className={classes.chatStatusIndicator} />
              <b className={classes.chatStatus}>Active</b>
            </div>
          </div>
        )}
        <PerfectScrollbar className={classes.scrollBarChat}>
          <>
            {isGPT ? (
              <>
                {loadingMessages ? (
                  <Preloader />
                ) : (
                  <>
                    {setting?.value !== '' && messagesGPT.length === 0 && (
                      <div className={classes.message}>
                        <div className={classes.messageUser}>
                          <div className={classes.chatAvatar}>
                            <b style={{ color: '#009ef7' }}>C</b>
                          </div>
                          <b className={classes.messageUserName}>ChatGPT</b>
                          <b className={classes.messageUserTime}>
                            {formatDate(new Date(), 'DD.MM.YYYY HH:mm:ss', true)}
                          </b>
                        </div>
                        <div className={classes.messageTextWrapper}>
                          <b className={classes.messageText}>{setting?.value.trim()}</b>
                        </div>
                      </div>
                    )}
                    {messagesGPT.map((item, index) => (
                      <div key={item.id}>
                        {item.role === 'user' && index === 0 && (
                          <div className={classes.myMessage}>
                            <div className={classes.myMessageUser}>
                              <b className={classes.myMessageTime}>
                                {formatDate(item.date, 'DD.MM.YYYY HH:mm:ss', true)}
                              </b>
                              <b className={classes.myMessageUserName}>{showUser || 'Я'}</b>
                              {profileMe.avatar && !showUser ? (
                                <img
                                  className={classes.messageUserAvatar}
                                  src={`${API_DOMAIN}/${
                                    profileMe.avatar.small || profileMe.avatar.path
                                  }`}
                                  alt='you'
                                />
                              ) : (
                                <div className={classes.chatAvatar}>
                                  {showUser ? (
                                    <b style={{ color: '#009ef7' }}>{showUser[0]}</b>
                                  ) : (
                                    <b style={{ color: '#009ef7' }}>
                                      {profileMe.login ? profileMe.login[0] : 'Я'}
                                    </b>
                                  )}
                                </div>
                              )}
                            </div>
                            <div className={classes.myMessageTextWrapper}>
                              <div
                                className={classes.myMessageTextBlock}
                                style={{
                                  padding: item.content.trim() ? '1.25rem' : 0,
                                }}
                              >
                                <b className={classes.myMessageText}>{item.content.trim()}</b>
                              </div>
                            </div>
                          </div>
                        )}
                        {item.role === 'assistant' && index === 0 && (
                          <div className={classes.message}>
                            <div className={classes.messageUser}>
                              <div className={classes.chatAvatar}>
                                <b style={{ color: '#009ef7' }}>C</b>
                              </div>
                              <b className={classes.messageUserName}>ChatGPT</b>
                              <b className={classes.messageUserTime}>
                                {formatDate(item.date, 'DD.MM.YYYY HH:mm:ss', true)}
                              </b>
                            </div>
                            <div className={classes.messageTextWrapper}>
                              <b className={classes.messageText}>{item.content.trim()}</b>
                            </div>
                          </div>
                        )}
                        {item.role === 'user' &&
                          index > 0 &&
                          messagesGPT[index - 1].role === 'assistant' && (
                            <div className={classes.myMessage}>
                              <div className={classes.myMessageUser}>
                                <b className={classes.myMessageTime}>
                                  {formatDate(item.date, 'DD.MM.YYYY HH:mm:ss', true)}
                                </b>
                                <b className={classes.myMessageUserName}>{showUser || 'Я'}</b>
                                {profileMe.avatar && !showUser ? (
                                  <img
                                    className={classes.messageUserAvatar}
                                    src={`${API_DOMAIN}/${
                                      profileMe.avatar.small || profileMe.avatar.path
                                    }`}
                                    alt='you'
                                  />
                                ) : (
                                  <div className={classes.chatAvatar}>
                                    {showUser ? (
                                      <b style={{ color: '#009ef7' }}>{showUser[0]}</b>
                                    ) : (
                                      <b style={{ color: '#009ef7' }}>
                                        {profileMe.login ? profileMe.login[0] : 'Я'}
                                      </b>
                                    )}
                                  </div>
                                )}
                              </div>
                              <div className={classes.myMessageTextWrapper}>
                                <div
                                  className={classes.myMessageTextBlock}
                                  style={{
                                    padding: item.content.trim() ? '1.25rem' : 0,
                                  }}
                                >
                                  <b className={classes.myMessageText}>
                                    {item.content.trim()}
                                  </b>
                                </div>
                              </div>
                            </div>
                          )}
                        {item.role === 'user' &&
                          index > 0 &&
                          messagesGPT[index - 1].role === 'user' && (
                            <div className={classes.myMessage}>
                              <div className={classes.myMessageUser}>
                                <b className={classes.myMessageTime}>
                                  {formatDate(item.date, 'DD.MM.YYYY HH:mm:ss', true)}
                                </b>
                              </div>
                              <div className={classes.myMessageTextWrapper}>
                                <div
                                  className={classes.myMessageTextBlock}
                                  style={{
                                    padding: item.content.trim() ? '1.25rem' : 0,
                                  }}
                                >
                                  <b className={classes.myMessageText}>
                                    {item.content.trim()}
                                  </b>
                                </div>
                              </div>
                            </div>
                          )}
                        {item.role === 'assistant' &&
                          index > 0 &&
                          messagesGPT[index - 1].role === 'user' && (
                            <div className={classes.message}>
                              <div className={classes.messageUser}>
                                <div className={classes.chatAvatar}>
                                  <b style={{ color: '#009ef7' }}>С</b>
                                </div>
                                <b className={classes.messageUserName}>ChatGPT</b>
                                <b className={classes.messageUserTime}>
                                  {formatDate(item.date, 'DD.MM.YYYY HH:mm:ss', true)}
                                </b>
                              </div>
                              <div className={classes.messageTextWrapper}>
                                <b className={classes.messageText}>{item.content}</b>
                              </div>
                            </div>
                          )}
                        {item.role === 'assistant' &&
                          index > 0 &&
                          messagesGPT[index - 1].role === 'assistant' && (
                            <div className={classes.message}>
                              <div className={classes.messageUser}>
                                <b className={classes.messageUserTime}>
                                  {formatDate(item.date, 'DD.MM.YYYY HH:mm:ss', true)}
                                </b>
                              </div>
                              <div className={classes.messageTextWrapper}>
                                <b className={classes.messageText}>{item.content}</b>
                              </div>
                            </div>
                          )}
                      </div>
                    ))}
                  </>
                )}
              </>
            ) : (
              <>
                {currentChat && (
                  <>
                    {loadingMessages || !chat || !me || !currentChat ? (
                      <Preloader />
                    ) : (
                      <>
                        {[...chat.order].reverse().map((item, index) => (
                          <div key={chat.posts[item].id.toString()}>
                            {chat.posts[item].user_id === me.id && index === 0 && (
                              <div className={classes.myMessage}>
                                <div className={classes.myMessageUser}>
                                  <b className={classes.myMessageTime}>
                                    {formatDate(new Date(chat.posts[item].create_at))}
                                  </b>
                                  <b className={classes.myMessageUserName}>Я</b>
                                  {profileMe.avatar ? (
                                    <img
                                      className={classes.messageUserAvatar}
                                      src={`${API_DOMAIN}/${
                                        profileMe.avatar.small || profileMe.avatar.path
                                      }`}
                                      alt='you'
                                    />
                                  ) : (
                                    <div className={classes.chatAvatar}>
                                      <b style={{ color: '#009ef7' }}>
                                        {profileMe.login ? profileMe.login[0] : 'Я'}
                                      </b>
                                    </div>
                                  )}
                                </div>
                                <div className={classes.myMessageTextWrapper}>
                                  <div
                                    className={classes.myMessageTextBlock}
                                    style={{
                                      padding: chat.posts[item].message.trim() ? '1.25rem' : 0,
                                    }}
                                  >
                                    <b className={classes.myMessageText}>
                                      {chat.posts[item].message.trim()}
                                    </b>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                      {chat.posts[item].metadata?.files &&
                                        chat.posts[item].metadata?.files.map(item => (
                                          <div
                                            key={item.id.toString()}
                                            style={{
                                              margin:
                                                chat.posts[item] &&
                                                chat.posts[item].message.trim()
                                                  ? 0
                                                  : '1.25rem',
                                            }}
                                          >
                                            {!isImage(item.name) ? (
                                              <FileChat file={item} isMy />
                                            ) : (
                                              <ImageChat
                                                image={item}
                                                scrollToBottom={scrollToBottom}
                                              />
                                            )}
                                          </div>
                                        ))}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                            {chat.posts[item].user_id !== me.id && index === 0 && (
                              <div className={classes.message}>
                                <div className={classes.messageUser}>
                                  <div className={classes.chatAvatar}>
                                    <b style={{ color: '#009ef7' }}>
                                      {nameChatPhone ? nameChatPhone[0] : 'С'}
                                    </b>
                                  </div>
                                  <b className={classes.messageUserName}>
                                    {nameChatPhone && nameChatPhone}
                                    {!nameChatPhone && (
                                      <>
                                        {!currentChat.company || isAdmin || isManager
                                          ? 'Собеседник'
                                          : fm('SUPPORT')}
                                      </>
                                    )}
                                  </b>
                                  <b className={classes.messageUserTime}>
                                    {formatDate(new Date(chat.posts[item].create_at))}
                                  </b>
                                </div>
                                <div className={classes.messageTextWrapper}>
                                  <b className={classes.messageText}>
                                    {chat.posts[item].message.trim()}
                                  </b>
                                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    {chat.posts[item].metadata?.files &&
                                      chat.posts[item].metadata?.files.map(item => (
                                        <div
                                          key={item.id.toString()}
                                          style={{
                                            margin:
                                              chat.posts[item] &&
                                              chat.posts[item].message.trim()
                                                ? 0
                                                : '1.25rem',
                                          }}
                                        >
                                          {!isImage(item.name) ? (
                                            <FileChat file={item} />
                                          ) : (
                                            <ImageChat
                                              image={item}
                                              scrollToBottom={scrollToBottom}
                                            />
                                          )}
                                        </div>
                                      ))}
                                  </div>
                                </div>
                              </div>
                            )}
                            {chat.posts[item].user_id === me.id &&
                              index > 0 &&
                              chat.posts[chat.order[index - 1]].user_id !== me.id && (
                                <div className={classes.myMessage}>
                                  <div className={classes.myMessageUser}>
                                    <b className={classes.myMessageTime}>
                                      {formatDate(new Date(chat.posts[item].create_at))}
                                    </b>
                                    <b className={classes.myMessageUserName}>Я</b>
                                    {profileMe.avatar ? (
                                      <img
                                        className={classes.messageUserAvatar}
                                        src={`${API_DOMAIN}/${
                                          profileMe.avatar.small || profileMe.avatar.path
                                        }`}
                                        alt='you'
                                      />
                                    ) : (
                                      <div className={classes.chatAvatar}>
                                        <b style={{ color: '#009ef7' }}>
                                          {profileMe.login ? profileMe.login[0] : 'Я'}
                                        </b>
                                      </div>
                                    )}
                                  </div>
                                  <div className={classes.myMessageTextWrapper}>
                                    <div
                                      className={classes.myMessageTextBlock}
                                      style={{
                                        padding: chat.posts[item].message.trim()
                                          ? '1.25rem'
                                          : 0,
                                      }}
                                    >
                                      <b className={classes.myMessageText}>
                                        {chat.posts[item].message.trim()}
                                      </b>
                                      <div
                                        style={{ display: 'flex', flexDirection: 'column' }}
                                      >
                                        {chat.posts[item].metadata?.files &&
                                          chat.posts[item].metadata?.files.map(item => (
                                            <div
                                              key={item.id.toString()}
                                              style={{
                                                margin:
                                                  chat.posts[item] &&
                                                  chat.posts[item].message.trim()
                                                    ? 0
                                                    : '1.25rem',
                                              }}
                                            >
                                              {!isImage(item.name) ? (
                                                <FileChat file={item} isMy />
                                              ) : (
                                                <ImageChat
                                                  image={item}
                                                  scrollToBottom={scrollToBottom}
                                                />
                                              )}
                                            </div>
                                          ))}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            {chat.posts[item].user_id === me.id &&
                              index > 0 &&
                              chat.posts[chat.order[index - 1]].user_id === me.id && (
                                <div className={classes.myMessage}>
                                  <div className={classes.myMessageUser}>
                                    <b className={classes.myMessageTime}>
                                      {formatDate(new Date(chat.posts[item].create_at))}
                                    </b>
                                  </div>
                                  <div className={classes.myMessageTextWrapper}>
                                    <div
                                      className={classes.myMessageTextBlock}
                                      style={{
                                        padding: chat.posts[item].message.trim()
                                          ? '1.25rem'
                                          : 0,
                                      }}
                                    >
                                      <b className={classes.myMessageText}>
                                        {chat.posts[item].message.trim()}
                                      </b>
                                      <div
                                        style={{ display: 'flex', flexDirection: 'column' }}
                                      >
                                        {chat.posts[item].metadata?.files &&
                                          chat.posts[item].metadata?.files.map(item => (
                                            <div
                                              key={item.id.toString()}
                                              style={{
                                                margin:
                                                  chat.posts[item] &&
                                                  chat.posts[item].message.trim()
                                                    ? 0
                                                    : '1.25rem',
                                              }}
                                            >
                                              {!isImage(item.name) ? (
                                                <FileChat file={item} isMy />
                                              ) : (
                                                <ImageChat
                                                  image={item}
                                                  scrollToBottom={scrollToBottom}
                                                />
                                              )}
                                            </div>
                                          ))}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            {chat.posts[item].user_id !== me.id &&
                              index > 0 &&
                              chat.posts[chat.order[index - 1]].user_id === me.id && (
                                <div className={classes.message}>
                                  <div className={classes.messageUser}>
                                    <div className={classes.chatAvatar}>
                                      <b style={{ color: '#009ef7' }}>С</b>
                                    </div>
                                    <b className={classes.messageUserName}>
                                      {nameChatPhone && nameChatPhone}
                                      {!nameChatPhone && (
                                        <>
                                          {currentChat.company || isAdmin || isManager
                                            ? 'Собеседник'
                                            : fm('SUPPORT')}
                                        </>
                                      )}
                                    </b>
                                    <b className={classes.messageUserTime}>
                                      {formatDate(new Date(chat.posts[item].create_at))}
                                    </b>
                                  </div>
                                  <div className={classes.messageTextWrapper}>
                                    <b className={classes.messageText}>
                                      {chat.posts[item].message}
                                    </b>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                      {chat.posts[item].metadata?.files &&
                                        chat.posts[item].metadata?.files.map(item => (
                                          <div
                                            key={item.id.toString()}
                                            style={{
                                              margin:
                                                chat.posts[item] &&
                                                chat.posts[item].message.trim()
                                                  ? 0
                                                  : '1.25rem',
                                            }}
                                          >
                                            {!isImage(item.name) ? (
                                              <FileChat file={item} />
                                            ) : (
                                              <ImageChat
                                                image={item}
                                                scrollToBottom={scrollToBottom}
                                              />
                                            )}
                                          </div>
                                        ))}
                                    </div>
                                  </div>
                                </div>
                              )}
                            {chat.posts[item].user_id !== me.id &&
                              index > 0 &&
                              chat.posts[chat.order[index - 1]].user_id !== me.id && (
                                <div className={classes.message}>
                                  <div className={classes.messageUser}>
                                    <b className={classes.messageUserTime}>
                                      {formatDate(new Date(chat.posts[item].create_at))}
                                    </b>
                                  </div>
                                  <div className={classes.messageTextWrapper}>
                                    <b className={classes.messageText}>
                                      {chat.posts[item].message}
                                    </b>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                      {chat.posts[item].metadata?.files &&
                                        chat.posts[item].metadata?.files.map(item => (
                                          <div
                                            key={item.id.toString()}
                                            style={{
                                              margin:
                                                chat.posts[item] &&
                                                chat.posts[item].message.trim()
                                                  ? 0
                                                  : '1.25rem',
                                            }}
                                          >
                                            {!isImage(item.name) ? (
                                              <FileChat file={item} />
                                            ) : (
                                              <ImageChat
                                                image={item}
                                                scrollToBottom={scrollToBottom}
                                              />
                                            )}
                                          </div>
                                        ))}
                                    </div>
                                  </div>
                                </div>
                              )}
                          </div>
                        ))}
                      </>
                    )}
                  </>
                )}
              </>
            )}
            <div ref={scrollBarref} />
          </>
        </PerfectScrollbar>
        {sendMessage && (currentChat || isGPT) && (
          <InputMessage
            currentChatId={
              typeof currentChat?.id === 'string'
                ? currentChat?.id
                : currentChat?.mattermost_id
            }
            isGPT={isGPT}
            sendMessage={sendMessage}
          />
        )}
        {btnDelete && (
          <Button onClick={btnDelete} variant='outlined' color='secondary'>
            {fm('COMMON.BUTTON.DELETE')}
          </Button>
        )}
      </>
    </Card>
  );
};

export default React.memo(ListMessages);
