import { makeStyles, createStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: any) =>
  createStyles({
    title: {
      marginBottom: 20,
      textTransform: 'capitalize',
    },
    filterColTop: {
      width: '100%',
      marginRight: 30,
      [theme.breakpoints.up('md')]: {
        width: '100%',
        minWidth: 326,
        maxWidth: 326,
      },
    },
    empty: {
      marginBottom: 20,
      marginTop: 20,
    },
    addBtn: {
      marginRight: 16,
    },
    actions: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    green: {
      color: '#fff',
      backgroundColor: '#566DC6FF',
    },
    emptyCompany: {
      width: 40,
      height: 40,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    label: {
      marginBottom: 7,
      fontSize: 14,
      fontWeight: 600,
      color: '#181c32',
    },
    tariffText: {
      margin: 0,
      padding: 0,
      color: '#369ff7',
      cursor: 'pointer',
    },
    preloaderContainer: {
      marginTop: 100,
      flex: 1,
    },
    container: {
      display: 'flex',
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      flexDirection: 'column',
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
      },
    },
    filterCol: {
      width: '100%',
      marginBottom: theme.spacing(3),
      [theme.breakpoints.up('md')]: {
        width: 326,
      },
    },
    filter: {
      marginBottom: 5,
      padding: '10px 30px 25px',
      overflow: 'visible',
    },
    tableContainer: {
      flex: 1,
    },
    companyNameAvatarWrapper: {
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
    },
    card: {
      padding: '25px 30px',
      overflow: 'visible',
    },
    containerPicker: {
      isplay: 'flex',
      flexDirection: 'row',
      marginTop: 16,
    },
    backdrop: {
      backdropFilter: 'blur(3px)',
      backgroundColor: 'rgba(0,0,30,0.4)',
    },
    btnDel: {
      marginTop: theme.spacing(2),
    },
  })
);
