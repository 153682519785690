import React, { useEffect } from 'react';
import { MenuItem, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';

import { useStyles } from '../hooks/useStyles';
import { useFormatMessage } from '../../../../hooks';
import { categoryLanguages } from '../../categories/utils/categoryLanguages';
import { useGetSettingGPT } from '../hooks/useGetSettingGPT';
import { useEditSettingGPT } from '../hooks/useEditSettingGPT';
import { ButtonWithLoader } from '../../../../components/ui/Buttons';
import Preloader from '../../../../components/other/Preloader/Preloader';

const DialogOpening = () => {
  const classes = useStyles();
  const fm = useFormatMessage();

  const [langLeft, setLangLeft] = React.useState('ru');
  const [langRight, setLangRight] = React.useState('en');

  const {
    fetchSetting: fetchSettingLeft,
    loading: loadingLeft,
    setting: settingLeft,
  } = useGetSettingGPT();
  const {
    fetchSetting: fetchSettingRight,
    loading: loadingRight,
    setting: settingRight,
  } = useGetSettingGPT();

  const {
    fetchSettingEdit: fetchSettingEditLeft,
    loadingEdit: loadingEditLeft,
    successEdit,
  } = useEditSettingGPT();
  const { fetchSettingEdit: fetchSettingEditRight, loadingEdit: loadingEditRight } =
    useEditSettingGPT();

  const { values, handleChange, handleSubmit } = useFormik({
    initialValues: {
      valueLeft: settingLeft?.value || '',
      valueRight: settingRight?.value || '',
    },
    enableReinitialize: true,
    onSubmit: values => {
      fetchSettingEditLeft({
        type: 'dialog_opening',
        locale: langLeft,
        value: values.valueLeft,
      });
      fetchSettingEditRight({
        type: 'dialog_opening',
        locale: langRight,
        value: values.valueRight,
        disableSuccess: true,
      });
    },
  });

  useEffect(() => {
    fetchSettingLeft('dialog_opening', langLeft);
    fetchSettingRight('dialog_opening', langRight);
  }, []);

  useEffect(() => {
    if (successEdit) {
      fetchSettingLeft('dialog_opening', langLeft);
      fetchSettingRight('dialog_opening', langRight);
    }
  }, [successEdit]);

  useEffect(() => {
    fetchSettingLeft('dialog_opening', langLeft);
  }, [langLeft]);

  useEffect(() => {
    fetchSettingRight('dialog_opening', langRight);
  }, [langRight]);

  return (
    <>
      <Typography style={{ marginTop: 16 }} variant='h6'>
        {fm('DIALOG.OPENING.LABEL')}
      </Typography>
      <div className={classes.formEdit}>
        <div className={classes.cardEdit}>
          <div style={{ padding: 16 }}>
            {loadingLeft ? (
              <Preloader />
            ) : (
              <>
                <TextField
                  select
                  margin='normal'
                  label={fm('AUTH.INPUT.LANG')}
                  name='language'
                  value={langLeft}
                  onChange={e => setLangLeft(e.target.value)}
                  variant='outlined'
                >
                  {categoryLanguages.map(lang => (
                    <MenuItem
                      disabled={langRight === lang.code}
                      key={lang.code}
                      value={lang.code}
                    >
                      {fm(`CATEGORY.${lang.code.toUpperCase()}.LANGUAGE`)}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  type='text'
                  margin='normal'
                  name='valueLeft'
                  variant='outlined'
                  multiline
                  minRows={10}
                  value={values.valueLeft}
                  onChange={handleChange}
                />
              </>
            )}
          </div>
        </div>
        <div className={classes.cardEdit}>
          <div style={{ padding: 16 }}>
            {loadingRight ? (
              <Preloader />
            ) : (
              <>
                <TextField
                  select
                  margin='normal'
                  label={fm('AUTH.INPUT.LANG')}
                  name='language'
                  value={langRight}
                  onChange={e => setLangRight(e.target.value)}
                  variant='outlined'
                >
                  {categoryLanguages.map(lang => (
                    <MenuItem
                      disabled={langLeft === lang.code}
                      key={lang.code}
                      value={lang.code}
                    >
                      {fm(`CATEGORY.${lang.code.toUpperCase()}.LANGUAGE`)}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  type='text'
                  margin='normal'
                  name='valueRight'
                  variant='outlined'
                  multiline
                  minRows={10}
                  value={values.valueRight}
                  onChange={handleChange}
                />
              </>
            )}
          </div>
        </div>
      </div>
      <div className={classes.containerBtns}>
        <ButtonWithLoader onPress={handleSubmit} loading={loadingEditLeft || loadingEditRight}>
          {fm('COMMON.BUTTON.SAVE')}
        </ButtonWithLoader>
      </div>
    </>
  );
};

export default React.memo(DialogOpening);
